import React from 'react';
import Layout from '../components/layout';
import { Container, Row, Col } from 'reactstrap';
import Banner from '../components/Banner';
import Map from '../components/Map';

const Contact = () => {
  return (
    <Layout title="Thanks">
      <Banner title="Thanks" />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <div className="page-content">We'll be in touch soon!</div>
          </Col>
        </Row>
      </Container>
      <Map />
    </Layout>
  );
};

export default Contact;
