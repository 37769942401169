import React from 'react';

const Map = () => (
  <div style={{ height: '400px', width: '100%' }}>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.068341878539!2d0.5072846515024623!3d51.38342197951494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8ccf95cc64ab5%3A0x78076ce9148a7bed!2sRiseley%20House%2C%204%20New%20Rd%2C%20Rochester%20ME1%201BD!5e0!3m2!1sen!2suk!4v1643382560071!5m2!1sen!2suk"
      width="600"
      height="450"
      style={{ border: 0, width: '100%' }}
      allowfullscreen=""
      loading="lazy"
      title="Map"
    ></iframe>
  </div>
);

export default Map;
